<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ this.$t('INSPECAO.AGENDAMENTOCONCLUIDO') }}</p>
        </header>
        <section class="modal-card-body is-flex is-justify-content-center is-flex-direction-column">
            <div class="field" style="width: 100%">
                <b-label>{{ this.$t('INSPECAO.ENVIAREMAILPARA') }}</b-label> 
                <b-input v-model="localPacienteEmail"></b-input>
                <b-button ref="emailButton" type="is-success" class="mt-2 is-pulled-right" @click="confirmarEnviarEmail">{{ this.$t('INSPECAO.ENVIAREMAIL') }}</b-button>
            </div>
        </section>
        <footer class="modal-card-foot is-flex is-justify-content-flex-end">
            <b-button @click="cancelar">{{ this.$t('INSPECAO.FECHAR') }}</b-button>
            <b-button ref="comprovanteButton" type="is-success" @click="abrirComprovante">{{ this.$t('INSPECAO.ABRIRCOMPROVANTE') }}</b-button>
        </footer>
    </div>
</template>

<style scoped>
    .modal-card {
        max-width: 500px; 
    }
</style>

  <script>
  export default {
    data() {
        return {
            localPacienteEmail: this.pacienteEmail,
        };
    },
    props: {
        pacienteEmail: String,
        pacienteNome: String,
        modeloNome: String,
        eventosSalvar: Array
    },
    mounted() {
        this.$nextTick(() => {
            const buttonRef = this.pacienteEmail ? 'emailButton' : 'comprovanteButton';
            this.focoNoButton(buttonRef);
        });
    },
    methods: {
        focoNoButton(ref) {
            if (this.$refs[ref] && this.$refs[ref].$el && typeof this.$refs[ref].$el.focus === 'function') {
                this.$refs[ref].$el.focus();
            }
        },
        onKeydown(event) {
            if (event.key === 'Escape') {
                this.$router.go(0);
            }
        },
        abrirComprovante() {
            const agendamentosIds = this.eventosSalvar.map(eventos => eventos.evento.id);
            if (agendamentosIds.length > 0) {
                window.open(`/api/agenda/imprimirAgendamentos?ids=${agendamentosIds}&modelo=${this.modeloNome}`);
            }
            this.$emit('close');
            this.$router.go(0);
        },
        confirmarEnviarEmail(){
            const agendamentosIds = this.eventosSalvar.map(eventos => eventos.evento.id);
            this.$http.post(`/api/agenda/enviarAgendamentoEmail?ids=${agendamentosIds}&modelo=${this.modeloNome}&pacienteNome=${this.pacienteNome}&pacienteEmail=${this.localPacienteEmail}`);
            this.$emit('close');
            this.$buefy.toast.open({
                duration: 20000,
                message:  this.$t('INSPECAO.EMAILENVIADO'),
                type: 'is-success',
                queue: false
            });  
            this.$router.go(0);
        },
        cancelar() {
            this.$emit('close');
            this.$router.go(0);
        },
    },
};
</script>