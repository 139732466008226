<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>

        <b-modal :active.sync="isModalImpressao" has-modal-card trap-focus aria-role="dialog" aria-modal>
            <modalImpressaoAgendamento :id="imprimir.id" :modelos="comprovanteModelos" @imprimir="imprimirModelo"></modalImpressaoAgendamento>
        </b-modal>

        <div style="padding: 1rem; border-bottom: 1px solid black;">
            <span style="display: flex; align-items: center">
                <span style="color: grey;">Agenda / &nbsp; </span> 
                <strong> Agendamentos</strong>
            </span>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column is-9">
                            <periodoHora
                            :dataInicial.sync="dataInicial"
                            :dataFinal.sync="dataFinal"
                            :placeHolder="$t('AGENDARELACAO.SELECIONEPERIODO')"
                            :selecionadas="[dataInicial,dataFinal]"
                            />
                        </div>
                        <div class="column is-1">
                            <button class="button is-success" type="button" @click="filtrar({dataInicial, dataFinal})">{{$t('AGENDARELACAO.FILTRAR')}}</button>
                        </div>
                        <div class="column is-1">
                            <button v-if="model.filter(x => x.criarGuia).length > 0" class="button is-primary" type="button" @click="criarGuia()">{{$t('AGENDARELACAO.CRIARGUIA')}}</button>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.FILTRARRESPONSAVEL')}}</b>
                            <b-select v-model="funcionarioIdFiltro" expanded>
                                <option :value="todos">Todos</option>
                                <template v-for="(item, index) in this.funcionariosAtivosFiltro">
                                    <option v-if="funcionarioIdFiltro == item.id" selected v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.FILTRARAGENDAMENTO')}}</b>
                            <b-select v-model="tipoAgendamentoIdFiltro" expanded>
                                <option :value="todos">Todos</option>
                                <template v-for="(item, index) in this.tiposAgendamentoAtivosFiltro">
                                    <option v-if="tipoAgendamentoIdFiltro == item.id" selected v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.FILTRARSTATUS')}}</b>
                            <b-select v-model="statusFiltro" expanded>
                                <option :value="todos">Todos</option>
                                <template v-for="(item, index) in this.statusAgenda">
                                    <option v-if="statusFiltro == item.valor" selected v-bind:key="index" :value="item.valor">
                                        {{item.descricao}}
                                    </option>
                                    <option v-else v-bind:key="index" :value="item.valor">
                                        {{item.descricao}}
                                    </option>
                                </template>
                            </b-select>
                        </div>
                        <div class="column is-3">
                            <b>{{$t('AGENDARELACAO.BUSCAPACIENTE')}}</b>
                            <b-field>
                                <b-input :placeholder="$t('AGENDARELACAO.PROCURAR')"
                                        v-model="procurar"
                                        icon="magnify"
                                        @input="loadAsyncData"></b-input>
                            </b-field>
                        </div>
                    </div>

                     <br />

                    <b-table class="box" :data="model" striped hoverable style="overflow-x: auto;">
                        <div class="columns">
                            <div class="column">
                                <b-switch v-model="exibeConvenio">
                                    {{$t('AGENDARELACAO.EXIBECONVENIO')}}
                                </b-switch>
                            </div>
                        </div>
                        <template>
                            <b-table-column v-slot="props" field="data" :label="$t('AGENDARELACAO.DATA')">
                                {{ props.row.dataHora | data}}
                                <br>
                                <b v-if="isConfirmado(props.row) && !isCancelado(props.row)" :title="$t('AGENDA.PACIENTECONFIRMADO')" class="has-text-success">&radic;</b>
                            </b-table-column>
                            <b-table-column v-slot="props" field="data" :label="$t('AGENDARELACAO.HORA')">
                                {{ formatarHoraMinutos(props.row.dataHora)}}
                            </b-table-column>
                            <b-table-column  v-slot="props" field="paciente" :label="$t('SISTEMA.PACIENTE')">
                                <p> <router-link target="_blank" :to="{ name: 'paciente', params: { id: props.row.pacienteId}}">
                                    {{ props.row.pacienteNome}}
                                </router-link>
                                <span v-if="props.row.observacao">
                                    <b-tooltip position="is-top" type="is-light" multilined>
                                        <template v-slot:content>
                                        <div v-html="tratamentoQuebraLinha(props.row.observacao)"></div> 
                                        </template>
                                        <b-icon icon="note-text-outline" size="is-small" type="is-info"></b-icon>
                                        </b-tooltip>
                                </span>
                                <span v-else>
                                </span> </p> 
                                <b-field v-if="props.row.pacienteResponsavelNome">
                                    <b-tooltip :label="$t('Responsável do Paciente')" type="is-dark" position="is-bottom">
                                    <b-tag type="is-info is-light">{{props.row.pacienteResponsavelNome}}</b-tag>
                                    </b-tooltip>
                                </b-field>
                            </b-table-column>
                             <b-table-column v-if="!exibeConvenio" v-slot="props" field="convenio" :label="$t('AGENDARELACAO.CONVENIO')">
                                
                                <template v-if="props.row.guiaConvenioNome">
                                        {{ props.row.guiaConvenioNome }}
                                </template>
                                <template v-else>
                                    {{ props.row.convenioNome }}
                                </template>
                            </b-table-column>
                            <b-table-column v-if="exibeConvenio" v-slot="props" field="endereco" :label="$t('AGENDARELACAO.ENDERECO')">
                                <span v-if="exibeConvenio">
                                {{ wordWrap(props.row.pacienteEndereco, 30)}}
                                </span>
                                <span v-if="!exibeConvenio">
                                    <template v-if="props.row.guiaConvenioNome">
                                        {{ props.row.guiaConvenioNome }}
                                    </template>
                                    <template v-else>
                                        {{ props.row.convenioNome }}
                                </template>
                                </span>
                            </b-table-column>
                            <b-table-column v-if="exibeConvenio" v-slot="props" field="bairro" :label="$t('AGENDARELACAO.BAIRRO')">
                                {{ props.row.pacienteBairro}}
                            </b-table-column>
                            <b-table-column v-if="exibeConvenio"  v-slot="props" field="cep" :label="$t('AGENDARELACAO.CEP')">
                                {{ props.row.pacienteCEP}}
                            </b-table-column>
                            <b-table-column v-if="exibeConvenio"  v-slot="props" field="cidade" :label="$t('AGENDARELACAO.CIDADE')">
                                {{ props.row.pacienteCidade}}
                            </b-table-column>
                            
                           
                            <b-table-column  v-slot="props" field="tipoagendamento" :label="$t('AGENDARELACAO.TIPOAGENDAMENTO')">
                                {{ props.row.tipoAgendamentoNome}}
                                <span v-if="props.row.encaixe">
                                    <b-tooltip :label="$t('Encaixe')" type="is-dark" position="is-bottom">
                                    <b-icon icon="puzzle" size="is-small" type="is-danger"></b-icon>
                                    </b-tooltip>
                                </span>
                                <span v-else>
                                </span>
                                <br>
                                <b-tooltip :label="$t('Funcionário Responsável')" type="is-dark" position="is-bottom">
                                <b-tag type="is-light" v-if="props.row.funcionarioNome">
                                {{ props.row.funcionarioNome}}
                                </b-tag>
                                </b-tooltip>
                            </b-table-column>
                            <b-table-column v-slot="props" field="exames" :label="$t('AGENDARELACAO.EXAMES')">
                                <template v-for="(exame,index) in props.row.exames">
                                    <span type="is-default" :key="index">
                                        {{ exame.exameNome }} 
                                        <b-tooltip expanded :label="$t('AGENDARELACAO.EXAMEURGENTE')" type="is-dark" position="is-bottom" append-to-body>
                                            <span class="mdi mdi-alert-circle-outline has-text-danger" style="font-size: 1rem;" v-if="exame.urgente"></span>
                                        </b-tooltip>
                                    </span>
                                </template>
                            </b-table-column>
                            <b-table-column v-slot="props" field="log" :label="$t('AGENDARELACAO.ACOES')">
                                    <span>
                                        <b-dropdown>
                                            <button class="button is-primary" slot="trigger">
                                                <b-icon icon="format-list-bulleted"></b-icon>
                                            </button>
                                            <b-dropdown-item v-if="!props.row.agendaCancelada" aria-role="listitem" @click="incluirNovoHorarioDia(props.row.id)">Editar</b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" v-if="!isConfirmado(props.row) && !props.row.agendaCancelada" @click="confirmarPaciente(props.row)">Confirmar </b-dropdown-item>
                                            <b-dropdown-item v-if="!props.row.agendaCancelada" aria-role="listitem" @click="confirmarCancelamento(props.row)">Cancelar </b-dropdown-item>
                                            <b-dropdown-item v-if="props.row.guiaAgendaExamesNome && props.row.guiaAgendaExamesNome.length>0 && !props.row.agendaCancelada" aria-role="listitem" @click="reagendamentoExames(props.row)">Reagendar Exames </b-dropdown-item>
                                            <hr v-if="!props.row.agendaCancelada" class="dropdown-divider" aria-role="menuitem">
                                            <b-dropdown-item aria-role="listitem" @click="modalIncluirAnexo(props.row)"><b-icon icon="link"></b-icon>Anexos</b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" @click="enviarEmail(props.row)"><b-icon icon="email"></b-icon>Enviar e-mail</b-dropdown-item>
                                            <b-dropdown-item v-if="!props.row.agendaCancelada" aria-role="listitem" @click="abrirImprimir(props.row)"><b-icon icon="printer"></b-icon>Imprimir </b-dropdown-item>
                                            <hr v-if="!props.row.agendaCancelada" class="dropdown-divider" aria-role="menuitem">
                                            <b-dropdown-item aria-role="listitem" @click="exibirHistorico(props.row)"><b-icon icon="clock"></b-icon>Histórico </b-dropdown-item>
                                        </b-dropdown>
                                    </span>
                                </b-table-column>
                                <b-table-column v-slot="props" field="guia" :label="$t('AGENDARELACAO.ABRIRGUIA')" custom-key="actions" numeric>
                                    <router-link v-if="props.row.guiaId" :to="{ name: 'guia', params: { id: props.row.guiaId }}" class="button is-normal is-primary" title="Editar">
                                        <b-icon icon="square-edit-outline" size="is-small"></b-icon>
                                    </router-link>
                                    <b-checkbox :disabled="props.row.naoPodeCriarGuia"
                                                @input="verificarPaciente(props.row)"
                                                :title="$t('AGENDARELACAO.CRIARGUIA')"
                                                v-else-if="props.row.pacienteId != null && props.row.convenioId != null && props.row.examesApelidos != null && !props.row.agendaCancelada"
                                                v-model="props.row.criarGuia">
                                    </b-checkbox>
                                </b-table-column>
                        </template>
                    </b-table>
                    <b-button class="button is-success" type="button" is-primary @click="exportar">Exportar</b-button>
                </article>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>

</style>

<script>
    import periodoHora from '@/components/periodoHora.vue'
    import moment from 'moment';
    import { mapMutations } from 'vuex'
    import historicoAlteracoes from "@/components/historicoAlteracoes.vue";
    import modalCancelandoAgenda from '@/components/atendimento/modalCancelandoAgenda'
    import modalImpressaoAgendamento from '@/components/atendimento/modalImpressaoAgendamento.vue';      
    import modalReagendamentoExames from '@/components/atendimento/modalReagendamentoExames.vue'
    import debounce from 'lodash/debounce';
    import { mapState } from 'vuex';
    import modalAnexosGenerico from "@/components/modalAnexosGenerico.vue";

    export default {
        components: {
            periodoHora,
            modalImpressaoAgendamento            
        },
        computed: {
            titleStack() {
                return [
                    this.$t('AGENDARELACAO.ATENDIMENTO'),
                    this.$t('AGENDARELACAO.RELACAOAGENDAMENTO'),
                ]
            },
            ...mapState([
                'config',
            ]),
        },
        
        data() {
            return {
                dataInicial: moment().set('hours',0).set('minutes',0).toDate(),
                dataFinal: moment().set('hours',23).set('minutes',59).toDate(),
                model: [],
                atendimentoInterno: true,
                coletaDomiciliar: true,
                item: {
                    tipoAgendamento: null,
                },
                funcionarioIdFiltro: null,
                funcionariosAtivosFiltro: [],
                tipoAgendamentoIdFiltro: null,
                statusFiltro: null,
                tiposAgendamentoAtivosFiltro: [],
                statusAgenda:[],
                isLoading: false,
                loadedData: false,
                todos: null,
                procurar: null,
                exibeConvenio: true,
                isModalImpressao: false,                
                imprimir: { id: null },
                comprovanteModelos: null,
            }
        },
        created(){
            this.exibeConvenio = this.config.exibirConvenioNaRelacaoDeAgendamento ? false : true;

            try {
                if (this.$route.query.dataInicial) this.dataInicial = moment(this.$route.query.dataInicial,'DD/MM/YYYY HH:mm').toDate();
                if (this.$route.query.dataFinal) this.dataFinal = moment(this.$route.query.dataFinal,'DD/MM/YYYY HH:mm').toDate();



                this.loadData();
            }catch(ex){
                console.error(ex);
            }
        },
        mounted() {
            this.$http.get('/api/search/FuncionariosAtivos')
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.funcionariosAtivosFiltro = data;
                }
            });

            this.$http.get('/api/search/ListarStatusAgenda')
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.statusAgenda = data;
                }
            });

            this.$http.get('/api/search/tiposagendamentos' +
                (this.$route.query.unidadeId != null ? `?unidadeId=${this.$route.query.unidadeId}` : ''))
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.tiposAgendamentoAtivosFiltro = data;
                }
            });
        },
        filters: {
            datahora:  (valor) => {
            if (!valor) return null;

            return  moment(valor).format('DD/MM/YYYY HH:mm:ss');
            }
        },
        methods: {
            ...mapMutations('componentes', [
                'SET_SIDEBAR',
            ]),
            exportar() {
                this.SET_SIDEBAR(false);
                setTimeout(() => {
                window.print();
            }, 200);
            },
            formatarHoraMinutos(dataHora) {
                const opcoes = { hour: '2-digit', minute: '2-digit' };
                return dataHora.toLocaleTimeString(undefined, opcoes);
            },
            tratamentoQuebraLinha(texto){
            return (texto) ? texto.replaceAll('\\n', '<br/>') : '';
            },
            verificarPaciente(item){
                if(item.criarGuia){
                    this.model.forEach(element => {
                        element.naoPodeCriarGuia = (item.pacienteId != element.pacienteId || item.convenioId != element.convenioId)
                    });
                }
                else if(this.model.filter(x => x.criarGuia).length == 0) {
                    this.model.forEach(element => {
                        element.naoPodeCriarGuia = false
                    });
                }
            },
            criarGuia(){
                const agendar = [];
                let observacoesInspecao = [];

                this.model.filter(x => x.criarGuia).forEach(element => {
                    const examesIds = [];

                    element.examesIds.forEach(exameId => {
                        examesIds.push(exameId);
                    });

                    agendar.push({
                        examesIds: examesIds,
                        evento: {
                            id: element.id
                        }
                    });

                    if(element.observacao && !observacoesInspecao.includes(element.observacao)) {
                        observacoesInspecao += element.observacao + '\n';
                    }

                });

                this.$router.push({ name: 'paciente', params: {
                    id: this.model.filter(x => x.criarGuia)[0].pacienteId, criarGuia: true,
                    convenioIdAgendar: this.model.filter(x => x.criarGuia)[0].convenioId,
                    agendar: agendar,
                    observacoesInspecao :  observacoesInspecao.length > 0 ? observacoesInspecao : null
                }});
            },
            wordWrap(str, maxWidth) {
                if(str == null){
                    return "";
                }

                const newLineStr = "\n";
                let res = '';
                while (str.length > maxWidth) {
                    let found = false;
                    for (let i = maxWidth - 1; i >= 0; i--) {
                        if (this.testWhite(str.charAt(i))) {
                            res = res + [str.slice(0, i), newLineStr].join('');
                            str = str.slice(i + 1);
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        res += [str.slice(0, maxWidth), newLineStr].join('');
                        str = str.slice(maxWidth);
                    }

                }

                return res + str;
            },
            testWhite(x) {
                const white = new RegExp(/^\s$/);
                return white.test(x.charAt(0));
            },
            exibirHistorico(item) {
                this.$buefy.modal.open({
                    parent: this,
                    component: historicoAlteracoes,
                    props: {
                        tabela: '',
                        chave: item.id,
                        toggle: true,
                        agendaLog: true,
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            filtrar(datas) {
                this.dataInicial = datas.dataInicial;
                this.dataFinal = datas.dataFinal;
                this.loadData();
            },

            loadData(){
                const params = [];

                if (this.dataInicial != null) params.push(`dataInicial=${moment(this.dataInicial).format('YYYY-MM-DDTHH:mm:ss')}`);
                if (this.dataFinal != null) params.push(`dataFinal=${moment(this.dataFinal).format('YYYY-MM-DDTHH:mm:ss')}`);
                if (this.statusFiltro != null) params.push(`status=${this.statusFiltro}`);
                if (this.tipoAgendamentoIdFiltro != null) params.push(`tipoAgendamentoId=${this.tipoAgendamentoIdFiltro}`);
                if (this.funcionarioIdFiltro != null) params.push(`funcionarioId=${this.funcionarioIdFiltro}`);
                if (this.$route.query.unidadeId != null) params.push(`unidadeId=${this.$route.query.unidadeId}`);
                if (this.procurar != null) params.push(`paciente=${this.procurar}`);
                if (this.$route.params.pacienteId != null) params.push(`pacienteId=${this.$route.params.pacienteId}`)

                this.isLoading = true;

                try {
                    this.$http.get(`/api/agenda/RelacaoAgenda?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null) {
                                this.model = data?.lista.map(d => {
                                    return {
                                        ...d,
                                        dataHora: moment(d.dataHora).toDate(),
                                        atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                                        atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                                    }
                                });
                                this.comprovanteModelos = data?.comprovanteModelos;
                            }
                            this.isLoading = false;
                            this.loadedData = true;
                        }).catch(e => {
                            throw e;
                        });
                }catch(e){
                    console.error(e);
                    this.isLoading = false;
                }
            },

            isConfirmado(item){              
                
                return (item.pacienteConfirmado);                
            },

            isCancelado(item){
                const statusCancelado = item.status & 1 == 1;
                return statusCancelado;
            },
              
            incluirNovoHorarioDia(idAgendamento){
             this.$router.push({ name: 'agendamento', params: { id: idAgendamento } })
            },

            confirmarPaciente(agendamento){
                this.$buefy.dialog.confirm({
                    title: 'Confirmar',
                    message: this.$t('AGENDA.CONFIRMAPACIENTE'),
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {
                        this.agendaStatus(agendamento,'confirmar')
                    }
                })
            },   

            confirmarCancelamento(item){
                const escalasEncontradas = this.model.filter(ev => ev.id == item.id);
                const data = this.$moment(escalasEncontradas[0].dataHora).format('YYYY-MM-DDTHH:mm:ss')

                this.$buefy.modal.open({
                    parent: this,
                    component: modalCancelandoAgenda,
                    props: {
                        id: item.id,                        
                        exames: item.examesApelidos,
                        dataHoraInicio: data,
                        dataHoraFim: item.dataHoraFim,
                    },
                    events: {
                        loadData: this.loadData
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            
            async agendaStatus(agendamento,acao) {
                const params = [];
                if (agendamento.id) params.push(`id=${agendamento.id}`);

                params.push(`acao=${acao}`);

                try {
                    const url = `/api/agenda/AgendaStatus?${params.join('&')}`;
                    const res = await this.$http.get(url);
                    this.loadData();
                } catch (e) {
                    console.error(e);
                }

            },

            abrirImprimir(item) {                
                this.imprimir = { id: null }
                this.isModalImpressao = true;
                this.imprimir.id = item.id;
            },
            modalIncluirAnexo(item) {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAnexosGenerico,
                    props: {
                        id: item.id,
                        guiaId: item.guiaId,
                        controller: {
                            mainController: 'atendimento',
                            methodAnexoController: 'anexoLaudo',
                            methodRemoverController: 'excluirAnexoLaudo'
                        },
                        titleName: 'Anexos de Agendamento',
                        tipoParam: {
                            firstType: 'agendaId',
                            secondType: 'id',
                        },
                    },
                    hasModalCard: true,
                    trapFocus: true
                });
            },
            enviarEmail(item){
                if(item.pacienteEmail == null || item.pacienteEmail == ""){
                    this.$buefy.toast.open({
                        duration: 3000,
                        message:  this.$t('PACIENTECADASTRO.NECESSARIOEMAIL'),
                        type: 'is-danger',
                        queue: false
                    });  
                }

                this.$buefy.dialog.prompt({
                    title: this.$t('PACIENTECADASTRO.TITULOEMAILPACIENTE'),
                    message: this.$t('PACIENTECADASTRO.MENSAGEMEMAILPACIENTE'),
                    inputAttrs: {
                        type: 'text',
                        placeholder: this.$t('PACIENTECADASTRO.EMAIL'),
                        value: item.pacienteEmail
                    }, 
                    confirmText: this.$t('PACIENTECADASTRO.CONFIRMAR'),
                    cancelText: this.$t('PACIENTECADASTRO.CANCELAR'),
                    trapFocus: true,
                    onConfirm: async (res) => {
                        const email = res;
                        await this.$http.post(`/api/agenda/enviarAgendamentoEmail?ids=${item.agendamentoId}&pacienteNome=${item.pacienteNome}&pacienteEmail=${email}`);
                        this.$emit('close');
                        this.$buefy.toast.open({
                            duration: 3000,
                            message:  this.$t('INSPECAO.EMAILENVIADO'),
                            type: 'is-success',
                            queue: false
                        });  
                    }
                });
            },
            imprimirModelo(data) {
                const form = document.createElement("form");
                const inputId = document.createElement("input");
                inputId.name = 'id';
                const inputModelo = document.createElement("input");
                inputModelo.name = 'modelo';

                form.method = "POST";
                form.action = "/api/agenda/Agendamento";

                inputId.value = this.imprimir.id;
                inputModelo.value = data.nome;

                form.appendChild(inputId);
                form.appendChild(inputModelo);

                data.variaveis?.forEach(v => {
                    const input = document.createElement("input");
                    input.name = v.nome;
                    input.value = v.valor;
                    form.appendChild(input);
                });


                document.body.appendChild(form);
                form.setAttribute('target','_blank');
                form.submit();
            },

            reagendamentoExames(evento){
            if (evento.guiaAgendaExamesNome.length > 1) {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalReagendamentoExames,
                    props: {
                        evento: evento                                        
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            }
            else {
                this.$router.push({ name: 'inspecao',params: { reagendamento: evento }  });  
            }
        },

            loadAsyncData: debounce(function () {
                this.loadData();
            }, 500),
            

        }
    }
</script>