<template>
    <section>
        <ValidationObserver ref="observer">
            <div class="box">
                <div class="columns">
                    <div class="column is-12">
                        <div class="columns">
                            <div class="column is-2">
                                <b-field :label="$t('GUIA.INDENTIFICACAO')">
                                    <b-input type="text" v-model="guia.id" readonly></b-input>
                                </b-field>
                            </div>
                            <div class="column is-2">
                                <b-field :label="$t('GUIA.REFERENCIA')">
                                    <b-input type="text" v-if="guia.id == 0" v-model="guia.referencia"></b-input>
                                    <b-input type="text" v-else v-model="guia.referencia" readonly></b-input>
                                </b-field>
                            </div>
                            <div class="column is-6">
                                <b-field :label="$t('GUIA.LOCALORIGEM')">
                                    <b-select :placeholder="$t('GUIA.SELECIONELOCAL')" v-model="guia.localDeOrigemId" required>
                                        <option v-for="(local, index) in locais" :key="index" :value="local.id">{{local.nome}}</option>
                                    </b-select>
                                </b-field>
                                <span v-show="guia.cnes">
                                    <small>{{$t('GUIAATENDIMENTO.CNES')}}
                                        <b>
                                            <span>{{guia.cnes}}</span>
                                        </b>
                                    </small>
                                </span>
                            </div>
                            <div class="column is-2">
                                <b-field :label="$t('GUIA.EMITIDA')">
                                    <b-datetimepicker v-if="guia.id == 0"  v-model="guia.dataDeEmissao"></b-datetimepicker>
                                    <b-input type="text" v-else v-model="guia.dataDeEmissaoText" readonly></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <searchIdName class="column is-8" :id.sync="guia.pacienteId" :label="$t('SISTEMA.PACIENTE')" table="Paciente" :leitura="true">
                            </searchIdName>
                            <div class="column is-2">
                                <b-field label="D.U.M." v-show="guia.pacienteSexo == 'F'">
                                    <b-datepicker editable v-model="guia.dataDaUltimaMenstruacao"></b-datepicker>
                                </b-field>
                            </div> 
                            <div class="column is-2">
                                <b-field :label="$t('GUIA.HORASJEJUM')" expanded>
                                    <b-tooltip expanded :label="$tc('GUIA.CALCULARHORASJEJUM', this.calcHorasJejum)"
                                        type="is-dark"
                                        position="is-top" >
                                        <b-input type="text" v-mask="['##:##']" icon="forward" v-model="guia.horasDeJejum" ref="horasDeJejum" icon-clickable @icon-click="abrirAlterarDataUltimaRefeicao" expanded></b-input>                                            
                                    </b-tooltip>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-4">
                                <span v-show="guia.pacienteInscricao"><small>{{$t('GUIA.INSCRICAO')}}: <b><span>{{guia.pacienteInscricao}}</span></b></small></span>
                            </div>
                            <div class="column is-4">
                                <span v-show="guia.pacienteSexo">
                                    <small>{{$t('GUIA.SEXO')}}: 
                                        <b>
                                            <span>{{guia.pacienteSexo}}</span>
                                        </b>
                                    </small>
                                </span>
                                &nbsp;&nbsp;
                                <span v-if="guia.pacienteDataDeNascimentoIdade && !guia.pacienteDataDeNascimentoIdade.includes('01/01/0001')">
                                    <small>{{$t('GUIA.NASCIMENTO')}}: 
                                        <b>
                                            <span>{{guia.pacienteDataDeNascimentoIdade}}</span>
                                        </b>
                                    </small>
                                </span>
                            </div>
                        </div>
                        <div class="columns" v-if="guia.convenioPortalControleMedico">                           
                            <searchIdName class="column is-8" :id.sync="guia.medicoId" :label="$t('SISTEMA.SOLICITANTE')" :campos="[{nome:'CRM', campo: 'conselhoNumero'}]" table="Medico" @item="escolheuMedico">
                                <slot>
                                    <b-button icon-left="square-edit-outline"
                                              tag="router-link"
                                              :to="{ name: 'medicoportal', params: { id: guia.medicoId, return: true, convenioIdAgendar: guia.convenioId, pacienteId: guia.pacienteId } }"
                                              :title="$t('GUIAATENDIMENTO.ABRIRINFORMACOES')"></b-button>
                                    <b-button icon-left="plus-box"
                                              tag="router-link"
                                              target="_blank"                                              
                                              :to="{ name: 'medicoportal',  params: { return: true },query: { retornarMedico:true}  }"
                                              :title="$t('GUIAATENDIMENTO.INCLUIRMEDICO')"></b-button>
                                </slot>
                            </searchIdName>
                            <div class="column is-2">
                                <b-field :label="$t('GUIA.CBO')">
                                    <b-select v-model="guia.medicoCBOSId" expanded>
                                        <option v-for="(cbos,index) in medicoCboss"
                                                :key="index"
                                                :value="cbos.id">
                                            {{ cbos.codigo }}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>                            
                        </div>                        
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-10">
                        <b-tabs position="is-right" type="is-boxed" @input="mudouItemTab" v-model="activeItemTab">
                            <b-tab-item>
                                <template slot="header">
                                    <span @click="selecionarTodosExames()"> {{$t('GUIA.EXAMES')}} <b-tag v-if="exames" type="is-dark" rounded> {{ exames.length }} </b-tag> </span>
                                </template>
                                <div class="columns is-multiline">
                                    <guiaExame v-for="(exame, index) in exames" :key="index" :portal="true" :guia="guia" :guiaexame="exame" :index="index" 
                                        v-on:excluirExame="excluirExame" :selecionado="exameSelecionado.includes(exame)" 
                                        v-on:loadPrecosExames="loadPrecosExames()"
                                        v-on:selecionarExame="selecionarExame(exame)"></guiaExame>
                                </div>
                            </b-tab-item>
                            <b-tab-item>
                                <template slot="header">
                                    <span @click="selecionarTodasAmostras()"> {{$t('GUIA.AMOSTRAS')}} <b-tag v-if="amostras" type="is-dark" rounded> {{ amostras.length }} </b-tag> </span>
                                </template>
                                <div class="columns is-multiline">
                                    <guiaAmostra v-for="(amostra, index) in amostras" :key="index" :amostra="amostra" :index="index" :mostra-paciente="false" :selecionada="amostraSelecionada.includes(amostra)" v-on:selecionarAmostra="selecionarAmostra(amostra)"></guiaAmostra>
                                </div>
                            </b-tab-item>
                        </b-tabs>
                        <div class="columns" v-show="isIncluindoExame">
                            <div class="column is-9">
                                <nav class="panel">
                                    <p class="panel-heading">
                                        {{$t('GUIA.INCLUIREXAME')}}
                                        <button type="button" class="delete is-pulled-right" aria-label="delete" @click="isIncluindoExame=!isIncluindoExame"></button>
                                    </p>
                                    <div class="panel-block">

                                        <b-input v-model="procurarExameTexto"
                                                 icon="magnify"
                                                 @input="inputListaExames"
                                                 @keydown.native.esc="isIncluindoExame=false"
                                                 @keydown.native.down="incluindoExameIndex++"
                                                 @keydown.native.up="incluindoExameIndex--"
                                                 @keydown.native.enter.prevent="incluirExame(listaExames[incluindoExameIndex])"
                                                 ref="procurarExameTextoInput"></b-input>

                                        <b-dropdown aria-role="list"
                                                    position="is-bottom-left"
                                                    v-model="procurarExamePor"
                                                    @input="$refs.procurarExameTextoInput.focus()">

                                            <button class="button" slot="trigger" slot-scope="{ active }">
                                                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                            </button>
                                            <b-dropdown-item aria-role="menu-item" custom>
                                                <small>{{$t('GUIA.PROCURAR')}}</small>
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="apelido">
                                                {{$t('GUIA.APELIDO')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="Nome">
                                                {{$t('GUIA.NOME')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="Codigo Externo">
                                                {{$t('GUIA.CODIGOEXTERNO')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item aria-role="listitem" value="Id">
                                                {{$t('GUIA.IDENTIFICACAO')}}
                                            </b-dropdown-item>
                                        </b-dropdown>
                                        <div class="column is-3 pl-3 pr-0">
                                            <b-input v-model="procurarMaterialTexto"
                                                    icon="magnify"
                                                    :placeholder="$t('GUIAATENDIMENTO.MATERIAL')"
                                                    @input="inputListaExames"
                                                    @keydown.native.esc="isIncluindoExame=false"
                                                    @keydown.native.down="incluindoExameIndex++"
                                                    @keydown.native.up="incluindoExameIndex--"
                                                    @keydown.native.enter.prevent="incluirExame(listaExames[incluindoExameIndex])">
                                            </b-input>
                                        </div>
                                        <div class="column is-3 pl-3">
                                            <b-input v-model="procurarConservanteTexto"
                                                    icon="magnify"
                                                    :placeholder="$t('GUIAATENDIMENTO.CONSERVANTE')"
                                                    @input="inputListaExames"
                                                    @keydown.native.esc="isIncluindoExame=false"
                                                    @keydown.native.down="incluindoExameIndex++"
                                                    @keydown.native.up="incluindoExameIndex--"
                                                    @keydown.native.enter.prevent="incluirExame(listaExames[incluindoExameIndex])">
                                            </b-input>
                                        </div>
                                    </div>
                                    <b-message v-if="!listaExames && !grupoExames">{{$t('GUIA.NENHUMEXAME')}}</b-message>
                                    <template v-for="(exame, index) in listaExames" >
                                        <template v-if="index == 0 || exame.quantidadeDeAmostras <= 1 || listaExames[index - 1].id != exame.id">
                                            <span class="panel-block columns is-multiline" style="border: 1px solid red; background-image: none !important; margin: 2px 0px;" 
                                                v-if="exame.bloqueado" :key="index">
                                                <div class="column is-2">
                                                    <h4 class="is-size-5 has-text-weight-bold">
                                                        {{ exame.apelido }}
                                                    </h4>
                                                </div>
                                                <div class="column is-6">
                                                    <p>{{ exame.nome }}</p>
                                                    <small class="is-italic" v-if="exame.quantidadeDeAmostras < 2 && 
                                                        exame.quantidadeDeAmostras != listaExames.filter(exameLista => exameLista.id == exame.id).length">
                                                        {{ exame.materialNome }} / {{ exame.conservanteNome }}
                                                    </small>
                                                    <small v-else>
                                                        <span v-for="(item, i) in listaExames.filter(exameLista => exameLista.id == exame.id)" :key="i">
                                                            {{ item.materialNome }} / {{ item.conservanteNome }}
                                                        </span>
                                                    </small>
                                                    <p v-if="exame.bloqueado">
                                                        <br />
                                                        <span class="tag"> {{$t('GUIA.BLOQUEADO')}}: {{exame.motivoBloqueio}}</span>
                                                    </p>
                                                </div>
                                                <div class="column is-3">
                                                    <p v-if="isExterno" class="is-italic">{{ exame.departamentoNome }}</p>
                                                    <p v-else class="is-italic">{{ exame.setorNome }}</p>
                                                </div>
                                                <div class="column is-1">
                                                    <span class="tag is-pulled-right" @click="mostraDetalhes(exame.id, $event)">
                                                        {{exame.codigo}}
                                                        <b-icon icon="format-list-bulleted"></b-icon>
                                                    </span>
                                                </div>
                                                <div class="column is-12" v-show="mostraDetalhesExameId == exame.id" :key="index">
                                                    <pre style="white-space: pre-wrap;       /* Since CSS 2.1 */
                                                        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                                                        white-space: -pre-wrap;      /* Opera 4-6 */
                                                        white-space: -o-pre-wrap;    /* Opera 7 */
                                                        word-wrap: break-word;  ">{{$t('GUIA.JEJUM')}}: {{exame.minimoJejum}}
        Prazo em Dias: {{ exame.prazoDeEntrega }} dias, {{ exame.prazoDeEntregaHoras }} horas
        Informações ao Paciente: {{exame.informacoesPaciente}}
        Informações ao Laboratório: {{exame.informacoesLaboratorio}}</pre>
                                                </div>
                                            </span>
                                            <a class="panel-block columns is-multiline" v-else :key="index" @click="incluirExame(exame)" v-bind:class="{ 'has-background-success': incluindoExameIndex == index }">
                                                <div class="column is-2">
                                                    <h4 class="is-size-5 has-text-weight-bold">
                                                        {{ exame.apelido }}
                                                    </h4>
                                                </div>
                                                <div class="column is-6">
                                                    <p>{{ exame.nome }}</p>
                                                    <small class="is-italic" v-if="exame.quantidadeDeAmostras < 2 && 
                                                        exame.quantidadeDeAmostras != listaExames.filter(exameLista => exameLista.id == exame.id).length">
                                                        {{ exame.materialNome }} / {{ exame.conservanteNome }}
                                                    </small>
                                                    <small v-else>
                                                        <span v-for="(item, i) in listaExames.filter(exameLista => exameLista.id == exame.id)" :key="i">
                                                            {{ item.materialNome }} / {{ item.conservanteNome }}
                                                        </span>
                                                    </small>
                                                </div>
                                                <div class="column is-3">
                                                    <p v-if="isExterno" class="is-italic">{{ exame.departamentoNome }}</p>
                                                    <p v-else class="is-italic">{{ exame.setorNome }}</p>
                                                </div>
                                                <div class="column is-1">
                                                    <span class="tag is-pulled-right" @click="mostraDetalhes(exame.id, $event)">
                                                        {{exame.codigo}}
                                                        <b-icon icon="format-list-bulleted"></b-icon>
                                                    </span>
                                                </div>
                                                <div class="column is-12" v-show="mostraDetalhesExameId == exame.id" :key="index">
                                                    <pre style="white-space: pre-wrap;       /* Since CSS 2.1 */
                                                        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
                                                        white-space: -pre-wrap;      /* Opera 4-6 */
                                                        white-space: -o-pre-wrap;    /* Opera 7 */
                                                        word-wrap: break-word;  ">{{$t('GUIA.JEJUM')}}: {{exame.minimoJejum}}
        Prazo em Dias: {{ exame.prazoDeEntrega }} dias, {{ exame.prazoDeEntregaHoras }} horas
        Informações ao Paciente: {{exame.informacoesPaciente}}
        Informações ao Laboratório: {{exame.informacoesLaboratorio}}</pre>
                                                </div>
                                            </a>
                                        </template>
                                    </template>
                                </nav>
                            </div>
                            <div v-show="grupoExames" class="column">
                                <div class="mb-1">
                                    <b-input 
                                        v-model="procurarGrupoTexto"
                                        @input="inputListaGrupo"
                                        type="text" 
                                        placeholder="Pesquisar Grupo">
                                    </b-input>
                                </div>
                                <nav class="panel" v-for="(grupo, index) in grupoExames" :key="index">
                                    <p class="panel-heading" @click="incluirGrupoExames(grupo)">
                                        {{ grupo.nome }}
                                    </p>
                                    <a class="panel-block" @click="incluirGrupoExames(grupo)">
                                        <p>
                                            <template v-for="item in grupo.itens">
                                                <b-tag v-if="item.bloqueado" :title="$t('GUIA.BLOQUEADO')" type="is-danger" :key="item.exameId">
                                                    {{ item.apelido }}
                                                </b-tag>
                                                <b-tag v-else type="is-info" :key="item.exameId">
                                                    {{ item.apelido }}
                                                </b-tag>
                                            </template>
                                        </p>
                                    </a>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-show="exameSelecionado.length > 0" class="buttons has-addons is-centered">
                            <b-button :title="$t('GUIA.CANCELARSELECIONADOS')"
                                    @click="abrirCancelamentoExame"
                                    v-if="!guia.guiaConferida && (exameSelecionado.every(x => !x.bloqueado && !x.cancelado && !x.digitado && !x.faturado && !x.materialEntregue))"
                                    icon-left="close-circle" type="has-text-danger"></b-button>

                            <b-button :title="$t('GUIA.BLOQUEAREXAMES')"
                                    @click="confirmarBloqueioExame"
                                    v-if="exameSelecionado.every(x => !x.bloqueado && !x.cancelado && !x.assinado)"
                                    icon-left="cancel" type="is-black"></b-button>
                            
                            <b-button :title="$t('GUIA.DESBLOQUEAREXAMES')"
                                      @click="confirmarDesbloqueioExame"
                                      v-show="exameSelecionado.every(x => x.bloqueado && !x.cancelado)"
                                      icon-left="checkbox-marked-circle-outline" type="has-text-success"></b-button>

                        </div>   
                        <div class="panel">
                            <b-button v-show="!isIncluindoExame && 
                                !guia.guiaConferida && usuario.convenioPodeIncluirGuia && !usuario.escondeIncluirGuia &&
                                (exames.length == 0 || (!exames.every(x => x.cancelado || x.faturado)))"
                                      icon-left="plus"
                                      expanded native-class="panel-block" type="is-success" @click="mostrarIncluindoExame">
                                {{$t('GUIA.INCLUIR')}}
                            </b-button>

                            <b-button type="is-light"  :title="$t('GUIA.IMPRIMIRCOMPROVANTE')"
                                      expanded                                      
                                      @click="imprimeComprovanteGuia(guia.id, guia.convenioModeloComprovante)">
                                {{$t('GUIA.COMPROVANTE')}}
                            </b-button>                            
                 
                        </div>                      
                    </div>
                </div>
                <div class="columns">
                    <b-tabs  @input="mudouObsTab" v-model="tabs"  multiline class="column is-12">
                        <b-tab-item value="observacoesDoPortal" :label="$t('GUIA.OBSERVACOESDOPORTAL')">
                            <textarea class="textarea" v-model="guia.observacoesDoPortal" rows="4"></textarea>
                        </b-tab-item>
                        <b-tab-item value="hipotese" :label="$t('GUIA.HIPOTESE')">
                            <div class="columns">
                                <div class="column is-one-fifth">
                                    <b-field :label="$t('GUIA.CID10')" :type="cid10type" :message="cid10message">
                                        <b-input v-model="guia.cid10"
                                                 @blur="getCid10"
                                                 v-mask="'A###'"
                                                 :loading="cid10loading"
                                                 icon="magnify"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('GUIAATENDIMENTO.DESCRICAO')">
                                        <b-input v-model="cid10descricao" readonly></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('GUIA.INDICACAO')">
                                        <b-input v-model="guia.indicacaoClinica"></b-input>
                                    </b-field>
                                </div>
                            </div>
                        </b-tab-item>
                        <b-tab-item value="medicamentos"  :label="$t('GUIA.MEDICAMENTOS')">
                            <b-taginput :data="filteredMedicamentos"
                                        autocomplete
                                        allow-new
                                        v-model="guia.medicamentos"
                                        icon="pill"
                                        :placeholder="$t('GUIA.INCLUIRMEDICAMENTO')"
                                        @typing="getMedicamentos">
                            </b-taginput>
                        </b-tab-item>
                        <b-tab-item  v-if="guia.id !== 0 && exames.some(x => x.pesquisaClinicaRespondida)"
                                    :label="$t('GUIA.RESPOSTAS')"
                                    icon="help-circle"
                                    value="respostas">

                            <p v-if="respostasPesquisas != null && respostasPesquisas.length == 0">
                               {{$t('GUIA.NENHUMARESPOSTA')}}
                            </p>

                            <b-loading :active.sync="isLoadingRespostas"></b-loading>
                            <respostasPesquisasVue :respostasPesquisas="respostasPesquisas" :exames="exames"></respostasPesquisasVue>                            

                        </b-tab-item>
                    </b-tabs>
                </div>
            </div>
            <nav class="level">
                <div class="level-item">
                    <p class="buttons">
                        
                        <b-button type="is-danger"
                                  native-type="button"
                                  icon-left="close-circle"
                                  @click="$router.back();">
                             {{$t('GUIA.CANCELAR')}}
                        </b-button>
                        <b-button type="is-success"
                                  :loading="salvandoDados"
                                   @click="salvarGuia"
                                  icon-left="check-circle">
                             {{$t('GUIA.SALVAR')}}
                        </b-button>
                    </p>
                </div>
            </nav>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapState } from 'vuex'
    import searchIdName from '@/components/searchIdName.vue'
    import guiaAmostra from '@/components/atendimento/amostra.vue'
    import guiaExame from '@/components/atendimento/guiaExame.vue'
    import respostasPesquisasVue from '@/components/atendimento/respostasPesquisas.vue'
    import modalCancelandoExame from '@/components/atendimento/modalCancelandoExame'
    import debounce from 'lodash/debounce'
    import modalComprovanteVariavel from '@/components/atendimento/modalComprovanteVariavel.vue'
    import modalCalculoTempoJejum from '@/components/atendimento/modalCalculoTempoJejum.vue'
    import modalBloqueioExame from '@/components/atendimento/modalBloqueioExame'
    import modalDesbloqueioExame from '@/components/atendimento/modalDesbloqueioExame'

    export default {
        data() {
            return {
                guia: {
                    id: null,
                    cid10: null,
                    agendamento: null,
                    dataDaUltimaMenstruacao: new Date()
                },
                exames: [],
                amostras: [],
                respostasPesquisas: [],
                guiasFilhas: null,
                anexos: null,

                salvandoDados: false,
                isIncluindoExame: false,
                incluindoExameIndex: 0,

                procurarExamePor: localStorage.procurarExamePor ?? 'apelido',
                procurarExameTexto: '',
                procurarGrupoTexto: '',
                exameCodigoTipoId: null,

                exameSelecionado: [],
                amostraSelecionada: [],
                activeItemTab: 0,

                listaExames: null,
                grupoExames: null,

                filteredMedicamentos: [],
                locais: [],

                cid10type: null,
                cid10loading: false,
                cid10message: null,
                cid10descricao: null,

                isLoadingRespostas: false,
                isExterno: true,

                mostraDetalhesExameId: null,

                modelosComprovante: [],
                modelosResultado: [],

                tabs: 'observacoesDoPortal',
                pagamentoGuia: false,                
                totalExames: 0,

                calcHorasJejum: 0
            }
        },
        components: {
            guiaAmostra,
            guiaExame,
            respostasPesquisasVue,
            searchIdName
        },
        computed: {
            ...mapState([
                'unidadeId',
                'config',
                'unidades',
                'usuario'
            ]),
            ...mapState('componentes', [
                'novoMedico'
            ]),
            ...mapState('senhaAtendimento', [
                'atendendo'
            ])       
        },
        mounted() {
            document.addEventListener('visibilitychange', this.verificaRetornarMedico, false);            
            this.loadGuia()
        },
        watch: {
            // call again the method if the route changes
            '$route': 'loadGuia',
        },
        methods: {
            verificaRetornarMedico() {                                 
                if(localStorage.retornarMedico > 0){               
                    this.guia.medicoId = localStorage.retornarMedico;                    
                    this.$forceUpdate();
                }                                
            }, 
            salvarGuia() {
                this.alterouLocalDeOrigem();

                if (this.guia.medicoId == 0 || this.guia.medicoId == null || this.guia.medicoId == '' || this.guia.medicoId == undefined) {
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIA.MEDICOOBRIGATORIO'),
                        message: this.$t('GUIA.MENSAGEMMEDICO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }                

                if(this.guia.localDeOrigemId == null || this.guia.localDeOrigemId == '' || this.guia.localDeOrigemId == undefined){
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIA.LOCALOBRIGATORIO'),
                        message: this.$t('GUIA.MENSAGEMLOCAL'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }

                if(this.exames == null || this.exames.length == 0){
                    this.$buefy.dialog.alert({
                        title:  this.$t('GUIA.EXAMEOBRIGATORIO'),
                        message: this.$t('GUIA.MENSAGEMEXAME'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }

                if (this.exames.filter(x => x.informarMedicamento == true).length > 0 && (this.guia.medicamentos == null || this.guia.medicamentos.length == 0)) {
                    this.tabs = 'medicamentos';
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIA.MEDICAMENTOOBRIGATORIO'),
                        message: this.$t('GUIA.MENSAGEMMEDICAMENTO'),
                        type: 'is-info',
                        hasIcon: true
                    });
                }
                if (this.exames.filter(x => x.informarHipoteseDiagnostica == true).length > 0 && (this.cid10type != 'is-success' || this.guia.indicacaoClinica == null)) {
                    this.tabs = 'hipotese';
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIA.HIPOTESEDIAGNOSTICAOBRIGATORIA'),
                        message: this.$t('GUIA.MENSAGEMHIPOTESEDIAGNOSTICA'),
                        type: 'is-info',
                        hasIcon: true
                    });
                }
                else if (this.exames.filter(x => x.informarDUM == true).length > 0 && this.guia.pacienteSexo == 'F'
                    && (this.guia.dataDaUltimaMenstruacao == null)) {
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.DATAMENSTRUACAO'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMDATAMENSTRUACAO'),
                        type: 'is-info',
                        hasIcon: true
                    });
                }
                else {
                    this.enviaSalvarGuia();
                }
            },
            enviaSalvarGuia() {
                localStorage.procurarExamePor = this.procurarExamePor;

                this.salvandoDados = true;
                this.guia.exames = this.exames

                this.$http.post('/api/portal/guia', this.guia)
                    .then(res => {
                        this.salvandoDados = false;
                        
                        if(res.data.pesquisaClinica) {
                            this.$router.push({ name: 'portalconveniopesquisaclinica', 
                                params: { 
                                    id: res.data.id,
                                    pesquisaPosGuia: true
                                }
                            });
                        }
                        else if (res.data.id) {
                            this.$router.push({ name: 'portalconvenioworkflow', 
                                params: { 
                                    id: res.data.id
                                }
                            });
                        }
                    })
                    .catch(err => {
                        this.salvandoDados = false

                        // restrições guia
                        if (err.status == 404) {
                            if (err.body.indexOf('peso') > 0) {
                                this.$buefy.dialog.prompt({
                                    title:  this.$t('GUIA.PESOOBRIGATORIO'),
                                    message: this.$t('GUIA.MENSAGEMPESO'),
                                    inputAttrs: {
                                        type: 'number',
                                        placeholder: 'Peso'
                                    },
                                    confirmText: this.$t('GUIA.CONFIRMA'),
                                    cancelText: this.$t('GUIA.CANCELAR'),
                                    trapFocus: true,
                                    onConfirm: (res) => {
                                        this.guia.pacientePeso = res;
                                        this.enviaSalvarGuia();
                                    }
                                });
                            }
                            else if (err.body.indexOf('altura') > 0) {
                                this.$buefy.dialog.prompt({
                                    title: this.$t('GUIA.ALTURAOBRIGATORIA'),
                                    message: this.$t('GUIA.MENSAGEMALTURA'),
                                    inputAttrs: {
                                        type: 'number',
                                        placeholder: 'Altura'
                                    },
                                    confirmText: this.$t('GUIA.CONFIRMA'),
                                    cancelText: this.$t('GUIA.CANCELAR'),
                                    trapFocus: true,
                                    onConfirm: (res) => {
                                        this.guia.pacienteAltura = res;
                                        this.enviaSalvarGuia();
                                    }
                                });
                            }
                            else if (err.body.indexOf('Tempo de Jejum') > 0) {
                                this.$refs.horasDeJejum.focus();
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: err.body,
                                    type: 'is-danger',
                                    queue: false
                                });
                            }
                            else if (err.body.indexOf('médico') > 0) {
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: err.body,
                                    type: 'is-danger',
                                    queue: false
                                })
                            }
                        }
                    })
            },
            limparSelecionados() {
                this.amostraSelecionada = []
                this.exameSelecionado = []
            },
            loadMedicoCbos() {
                this.$http.get('/search/MedicoCboss?id=' + this.guia.medicoId)
                    .then(res => {
                        this.medicoCboss = res.data
                        if(this.medicoCboss != null && this.medicoCboss.length > 0 && this.guia.medicoCBOSId == null){
                            this.guia.medicoCBOSId = this.medicoCboss[0].id
                        }
                    })
            },
            loadGuia() {
                this.limparSelecionados()
                this.respostasPesquisas = null
                this.activeItemTab = 0

                this.$http.get('/search/Local')
                    .then(res => {
                        this.locais = res.data
                    });

                if (this.$route.params.pacienteId) {
                    this.$http.get('/api/portal/create?pacienteId=' + this.$route.params.pacienteId)
                        .then(res => {
                            this.guia = res.data
                            this.temNovoMedico()
                            this.loadInformacoesGuia()
                            this.temNovoMedico()
                        })
                }

                if (this.$route.params.id) {
                    this.$http.get('/api/portal/guia?id=' + this.$route.params.id)
                        .then(res => {
                            this.guia = res.data[0]
                            this.temNovoMedico()
                            this.loadGuiaExames(this.guia.id)
                            this.getCid10();
                        })
                }

                this.$http.get('/api/atendimento/ListarComprovanteModelos')
                    .then(res => {
                        this.modelosComprovante = res.data;
                    })                
                

            },
            alterouLocalDeOrigem() {
                if (this.guia.localDeOrigemId != null && this.guia.id == 0) {
                    localStorage.localDeOrigemId = this.guia.localDeOrigemId;
                }
            },
            loadInformacoesGuia() {
                if (this.guia.dataDeEmissao != null) {
                    this.guia.dataDeEmissao = new Date(this.guia.dataDeEmissao);
                    this.guia.dataDeEmissaoText = this.$moment(this.guia.dataDeEmissao).format("DD/MM/YYYY HH:mm");
                }
                else {
                    this.guia.dataDeEmissao = new Date();
                }

                if (this.guia.dataDeRetorno != null) {
                    this.guia.dataDeRetorno = new Date(this.guia.dataDeRetorno);
                    this.guia.dataDeRetornoAnterior = this.guia.dataDeRetorno;
                }
                
                if (this.guia.dataDaUltimaMenstruacao != null) {
                    this.guia.dataDaUltimaMenstruacao = new Date(this.guia.dataDaUltimaMenstruacao);
                }
   
                if (localStorage.localDeOrigemId != null && this.guia.id == 0) {
                    this.guia.localDeOrigemId = localStorage.localDeOrigemId;
                }

            },
            mostraDetalhes(id, event) {
                event.stopPropagation();
                if (this.mostraDetalhesExameId == id) {
                    this.mostraDetalhesExameId = null;
                }
                else {
                    this.mostraDetalhesExameId = id;
                }
            },
            loadGuiaExames(id) {
                this.loadInformacoesGuia();

                this.$http.get('/api/atendimento/GuiaExamesEdit?guiaId=' + id)
                    .then(resexa => {
                        this.exames = resexa.data?.map(e => {
                                return {
                                    ...e,
                                  sadtAutorizacao : (e.sadtAutorizacao)  ? new Date(e.sadtAutorizacao) : null,
                                  sadtAutorizacaoSenhaValidade : (e.sadtAutorizacaoSenhaValidade) ? new Date(e.sadtAutorizacaoSenhaValidade) : null,
                                }
                            });                        
                        this.loadGuiaAmostras(id)
                    })
            },
            loadGuiaAmostras(id) {
                this.$http.get('/api/atendimento/GuiaAmostrasEdit?guiaId=' + id)
                    .then(resamos => {
                        this.amostras = resamos.data

                        if(this.amostras.filter(amostra => amostra.incidencia).length > 0){
                            this.guia.incidencia = true;

                            this.amostras.forEach(amostra => {
                                if(amostra.incidencia){
                                    amostra.itens.forEach(item => {
                                        if(this.exames.filter(exame => exame.item == item.guiaExame.item && id == item.guiaExame.guiaId).length > 0){
                                            this.exames.filter(exame => exame.item == item.guiaExame.item && id == item.guiaExame.guiaId)[0].statusTitle = this.exames.filter(exame => exame.item == item.guiaExame.item && id == item.guiaExame.guiaId)[0].statusTitle + ", Incidência"
                                            this.exames.filter(exame => exame.item == item.guiaExame.item && id == item.guiaExame.guiaId)[0].incidencia = true
                                        }
                                    })
                                }
                            });
                        }
                    })
            },
            loadRespostasPesquisas() {
                this.isLoadingRespostas = true
                this.$http.get('/api/portal/RespostasPerguntas?id=' + this.guia.id)
                    .then(res => {
                        res.data.forEach(x => {
                            x.respostas.forEach(resposta => {
                                if(resposta.perguntaItem.aceitaData){
                                    resposta.descritivoData = resposta.descritivo != null ? new Date(resposta.descritivo) : null 
                                    
                                }
                            })
                        })

                        this.respostasPesquisas = res.data;
                    })
                    .finally(() => {
                        this.isLoadingRespostas = false
                    })
            },
            inputListaExames: debounce(function () {
                this.loadListaExames()
            }, 500),
            loadListaExames() {
                if (this.guia.convenioId) {
                    const params = {
                        texto: this.procurarExameTexto,
                        material: this.procurarMaterialTexto,
                        conservante: this.procurarConservanteTexto,
                        campo: this.procurarExamePor,
                        exameCodigoTipoId: this.guia.convenioTipoExameCodigoId,
                        convenioId: this.guia.convenioId
                    }
                    this.$http.get('/api/atendimento/EditGuiaListaExames', { params })
                        .then(res => {
                            this.listaExames = res.data
                            this.incluindoExameIndex = 0
                        })
                }
            },            
            inputListaGrupo: debounce(function () {
                this.loadGrupoExames()
            }, 500),
            loadGrupoExames() {
                if (this.guia.convenioId) {
                    const params = {
                        texto: this.procurarGrupoTexto,
                        convenioId: this.guia.convenioId
                    }
                    this.$http.get('/api/atendimento/EditGuiaGrupoExames', { params })
                        .then(res => {
                            this.grupoExames = res.data
                        })
                }
            },
            mudouItemTab() {
                this.limparSelecionados()
            },
            mostrarIncluindoExame() {
                this.isIncluindoExame = true
                if (this.listaExames == null) {
                    this.loadListaExames()
                    this.loadGrupoExames()
                }
                this.$nextTick(() => {
                    this.$refs.procurarExameTextoInput.focus()
                })
            },
            incluirGrupoExames(grupo) {
                grupo.itens.forEach(x => {
                    this.incluirExame(x)
                })
            },
            incluirExame(exame) {
                if(exame.bloqueado){
                    return;
                }

                const id = exame.id ? exame.id : exame.exameId;

                if(this.exames.filter(x => x.exameId == id && !exame.bloqueioExameRepetido).length > 0 && 
                    (exame.genero == null || exame.genero == this.guia.pacienteSexo)){
                    this.$buefy.dialog.confirm({
                        title: this.$t('GUIA.EXAMEINCLUIDO'),
                        message: this.$t('GUIA.MENSAGEMINCLUIDO') + '<b>' + exame.apelido + '</b> ?',
                        confirmText: this.$t('GUIA.CONFIRMA'),
                        type: 'is-info',
                        hasIcon: true,
                        cancelText: this.$t('GUIA.CANCELAR'),                                               
                        onConfirm: () => {
                            this.confirmaInclusaoExame(exame);
                        }
                    });
                 } else if(this.exames.filter(x => x.exameId == id && exame.bloqueioExameRepetido).length > 0) {
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIA.BLOQUEIOEXAME'),
                        message: this.$t('GUIA.MESSAGEMBLOQUEIO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return null
                } else if(exame.genero != null && exame.genero != this.guia.pacienteSexo){
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIA.BLOQUEIOEXAMEGENERO'),
                        message: this.$t('GUIA.BLOQUEIOEXAMEGENEROMENSAGEM'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return null
                }
                else {
                    this.confirmaInclusaoExame(exame);
                }

                this.$refs.procurarExameTextoInput.focus();
            },
            confirmaInclusaoExame(exame) {
                const id = exame.id ? exame.id : exame.exameId;

                let localUrgente = false;
                
                const local = this.locais.filter(x => x.id == this.guia.localDeOrigemId)[0];
                if(local){
                    localUrgente = local.urgente;
                }

                this.exames.push({
                    item: 0,
                    exameId: id,
                    exameApelido: exame.apelido,
                    exameGenero: exame.genero,
                    exameNome: exame.nome,
                    recipienteId: exame.recipienteId,
                    dataDeSolicitacao: new Date(),
                    dataDaUltimaMenstruacao: exame.dataDaUltimaMenstruacao,
                    urgente: localUrgente == true ? true : localStorage.caraterUrgencia == "true", // localstorage armazena em string
                    informarMedicamento: exame.informarMedicamento,
                    informarHipoteseDiagnostica: exame.informarHipoteseDiagnostica,
                    informacoesPaciente: exame.informacoesPaciente,
                    informarPeso: exame.informarPeso,
                    informarAltura: exame.informarAltura,
                    informarDUM: exame.informarDUM,
                    exameDePerfil: exame.exameDePerfil,
                    complementoDoExame: exame.complementoDoExame ? exame.complementoDoExame : null,
                })
                this.activeItemTab = 0
                this.procurarExameTexto = ''
                this.procurarMaterialTexto = ''
                this.procurarConservanteTexto = ''
                
                let exaPreco = null;
                if(this.exames.filter(x => x.exameDePerfil).length > 0) {
                    this.exames = this.exames.map(exa => {
                        exaPreco = 0;
                        if(exa.preco == null || exa.preco == undefined) {
                            exa.preco = 0;
                        } 

                        return exa;
                    });

                    this.loadPrecosExames();
                }

                //busca se tem exames complementares
                this.$http.get('/api/atendimento/ExamesComplementares?exameId=' + id + '&convenioId=' + this.guia.convenioId  + '&recipienteIdPai=' + exame.recipienteId)
                    .then(res => res.data)
                    .then((exames) => {
                        if(exames != null && exames.length > 0){          
                            exames.forEach(exameComplementar => {    
                                const idComplementar = exame.id ? exameComplementar.id : exameComplementar.exameId;

                                if(this.exames.filter(x => x.exameId == idComplementar).length > 0){
                                    this.$buefy.dialog.alert({
                                        title: this.$t('GUIA.INSERIREXAME'),
                                        message: this.$t('GUIA.MENSSAGEMINSERIREXAME') + '<b>' + exameComplementar.apelido + '</b>' + this.$t('GUIA.NESTAGUIA'),
                                        type: 'is-info',
                                        hasIcon: true
                                    });
                                }

                                if(exameComplementar.genero != null && exameComplementar.genero != this.guia.pacienteSexo){
                                    this.$buefy.dialog.alert({
                                        title: this.$t('GUIA.BLOQUEIOEXAME'),
                                        message: this.$t('GUIA.EXAMECOMPLEMENTARMENSAGEM') + exameComplementar.apelido + this.$t('GUIA.NAOPODEINSERIR'),
                                        type: 'is-warning',
                                        hasIcon: true
                                    });
                                }
                                else {
                                    this.exames.push({
                                        item: 0,
                                        exameId: idComplementar,
                                        exameApelido: exameComplementar.apelido,
                                        exameNome: exameComplementar.nome,
                                        exameGenero: exameComplementar.genero,
                                        recipienteId: exameComplementar.recipienteId,
                                        dataDeSolicitacao: new Date(),
                                        dataDaUltimaMenstruacao: exameComplementar.dataDaUltimaMenstruacao,
                                        urgente: localUrgente == true ? true : localStorage.caraterUrgencia == "true", // localstorage armazena em string
                                        informarMedicamento: exameComplementar.informarMedicamento,
                                        informarHipoteseDiagnostica: exameComplementar.informarHipoteseDiagnostica,
                                        informarPeso: exameComplementar.informarPeso,
                                        informarAltura: exameComplementar.informarAltura,
                                        informarDUM: exameComplementar.informarDUM,
                                        exameDePerfil: exame.exameDePerfil,
                                        complementoDoExame: id,
                                    })
                                }
                            });  
                        }

                    })
            },
            loadPrecosExames() {
                const examesPreco = {
                    exames: this.exames.filter(x => x.item == 0).map(x => {
                        return{
                            exameId: x.exameId,
                            setorId: x.setorId,
                            preco: 0.0,
                            itemExame: x.item,
                            urgente: x.urgente
                        }
                    }),
                    convenioId: this.guia.convenioId
                }

                this.$http.post('/api/manutencao/ExamesCalculaPrecoParticular', examesPreco)
                .then(res => res.data)
                .then(data => {                        
                    this.exames.filter(x => x.item == 0).forEach(e => {
                        
                        const exame = data.exames.filter(x => x.exameId == e.exameId )[0];

                        if(e.complementoDoExame){

                            const examePai = this.exames.filter(c => c.exameId == e.complementoDoExame);
                            
                            e.preco = examePai[0].exameDePerfil && !examePai[0].exameDeFaturamento
                                ? 0
                                : examePai[0].exameDePerfil && examePai[0].exameDeFaturamento 
                                    ? exame.preco
                                    : exame.preco;

                        }else {
                            e.preco = exame?.preco ?? 0
                        }
                    });

                    this.$forceUpdate();
                })
            },
            excluirExame({index, guiaexame}) {
                this.exames.splice(index, 1) 

                if(guiaexame.complementoDoExame != null && guiaexame.complementoDoExame > 0) {
                    let count = 0;
                    //remove exame pai
                    this.exames.filter(exames => exames.exameId == guiaexame.complementoDoExame).forEach( exame => {
                        if((count == 0 && exame.exameDePerfil && !exame.exameDeFaturamento) || (count == 0 && exame.exameDePerfil && exame.exameDeFaturamento)){
                            const exameIndex = this.getExameIndex(exame.exameId);

                            this.exames.splice(exameIndex, 1);
                            count += 1;

                        }
                    });

                    //remove vinculo do exame filho com o pai(removido anteriormente)
                    this.exames.filter(exames => exames.complementoDoExame == guiaexame.complementoDoExame).forEach( exame => {
                        exame.complementoDoExame = null;                        
                    });

                }else {
                    this.exames.filter(exames => exames.complementoDoExame == guiaexame.exameId).forEach( exame => {
                        exame.complementoDoExame = null;
                        
                       this.loadPrecosExames();                        
                    });
                }
            },
            getExameIndex(exameId) {
                let exameIndex = 0;

                const result = this.exames.filter((exa) => {
                    return exa.exameId == exameId;
                });
                    
                for(const exame of result){
                    exameIndex = this.exames.indexOf(exame);    
                }

                return exameIndex;
            },
            selecionarTodosExames() {
                if (this.activeItemTab == 0) {
                    this.exames.forEach(x => {
                        this.selecionarExame(x)
                    })
                }
            },            
            temNovoMedico() {
                if(this.$route.params.solicitanteId){
                    this.guia.medicoId = this.$route.params.solicitanteId
                }
                
                if (this.novoMedico != undefined) {
                    this.incluiuNovoMedico = true
                    this.guia.medicoId = this.novoMedico.id
                    this.guia.medicoNome = this.novoMedico.nome
                    this.medicoCboss = this.novoMedico.cboss
                    if(this.medicoCboss != null && this.medicoCboss.length > 0 && this.guia.medicoCBOSId == null){
                        this.guia.medicoCBOSId = this.medicoCboss[0].id
                    }

                    this.$store.commit('componentes/SET_NOVO_MEDICO', undefined)
                } else {
                    if (this.guia.medicoId) {
                        this.loadMedicoCbos()
                    }
                }
            },
            escolheuMedico(val){
                if(val != null && val.data != null && val.data.cboss != null){
                    const cboss = val.data.cboss.split(',');
                    this.medicoCboss = [];
                    this.guia.medicoCBOSId = null;

                    cboss.forEach(item => {
                        this.medicoCboss.push({
                            id: item.split('_')[0],
                            codigo: item.split('_')[1]
                        });
                    });

                    if(this.medicoCboss != null && this.medicoCboss.length > 0 && this.guia.medicoCBOSId == null){
                        this.guia.medicoCBOSId = this.medicoCboss[0].id
                    }
                }
            },            
            selecionarExame(exame) {
                if (this.exameSelecionado == null) {
                    this.exameSelecionado = [exame]
                } else {
                    if (this.exameSelecionado.includes(exame)) {
                        this.exameSelecionado = this.exameSelecionado.filter(function (x) { return x != exame })
                    } else {
                        this.exameSelecionado.push(exame)
                    }
                }
            },
            selecionarTodasAmostras() {
                if (this.activeItemTab == 1) {
                    this.amostras.forEach(x => {
                        this.selecionarAmostra(x)
                    })
                }
            },
            selecionarAmostra(amostra) {
                if (this.amostraSelecionada == null) {
                    this.amostraSelecionada = [amostra]
                } else {
                    if (this.amostraSelecionada.includes(amostra)) {
                        this.amostraSelecionada = this.amostraSelecionada.filter(function (x) { return x != amostra })
                    } else {
                        this.amostraSelecionada.push(amostra)
                    }
                }
            },
            abrirCancelamentoExame() {
                const exameDePerfilOuComplementar = [];
                const examesSelecionados = [];
                
                this.exameSelecionado.forEach(exame => {
                    examesSelecionados.push(exame);
                });
                    
                this.exameSelecionado.forEach(exame => {
                    if (exame.complementoDoExame != null && exame.complementoDoExame > 0 || exame.exameDePerfil) {
                        exameDePerfilOuComplementar.push(exame);
                    }
                });

                if (exameDePerfilOuComplementar.length > 0) {
                    this.$buefy.dialog.confirm({
                        title: this.$t('GUIAATENDIMENTO.CANCELAREXAMEDEPERFIL'),
                        message: this.$t('GUIAATENDIMENTO.MSGCANCELAREXAMEDEPERFIL'),
                        type: 'is-warning',
                        confirmText: this.$t('SISTEMA.CONFIRMAR'),
                        cancelText: this.$t('GUIAATENDIMENTO.CANCELAR'),                                               
                        onConfirm: () => {
                            this.$buefy.modal.open({
                                parent: this,
                                component: modalCancelandoExame,
                                props: {
                                guia: this.guia,
                                selecionado: this.exameSelecionado
                                },
                                events: {
                                loadGuia: this.loadGuia
                                },
                                hasModalCard: true,
                                trapFocus: true
                            });
                        },
                    });
                } else {
                    this.$buefy.modal.open({
                        parent: this,
                        component: modalCancelandoExame,
                        props: {
                            guia: this.guia,
                            selecionado: this.exameSelecionado
                        },
                        events: {
                            loadGuia: this.loadGuia
                        },
                        hasModalCard: true,
                        trapFocus: true
                    })
                }
            },
            confirmarBloqueioExame() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalBloqueioExame,
                    props: {
                        guia: this.guia,
                        selecionado: this.exameSelecionado,                        
                        result: null,
                        portal: true,
                    },
                    hasModalCard: true,
                    events: {
                        loadGuia: this.loadGuia
                    },
                    trapFocus: true,
                    fullScreen: true
                })
            },
            confirmarDesbloqueioExame() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDesbloqueioExame,
                    props: {
                        guia: this.guia,
                        selecionado: this.exameSelecionado,
                        portal: true,
                    },
                    hasModalCard: true,
                    events: {
                        loadGuia: this.loadGuia
                    },
                    trapFocus: true,
                    fullScreen: true
                })
            },

            mudouObsTab(value) {                
                if (value == 'respostas') {
                    if (this.respostasPesquisas == null || this.respostasPesquisas.length == 0) {
                        this.loadRespostasPesquisas()
                    }
                } else if (value == 'historicoAlteracoes') {
                    if (this.historicoAlteracoes == null) {
                        this.loadHistoricoAlteracoes()
                    }
                } 
            },            

            getMedicamentos(text) {
                if(text){                    
                    const textReplaced = text.replace(/[^\w\s]/gi, '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\\]/gi, '').trim();	                        

                    if(textReplaced){               
                        this.$http.get('/api/search/Medicamentos', { params: { nome: textReplaced } })
                            .then(res => {
                                this.filteredMedicamentos = res.data
                            });
                    }
                }
            },          
            getCid10() {
                this.cid10loading = true
                this.cid10message = null;
                if(this.guia.cid10){
                    this.$http.get('/api/search/Cid10?nome=' + this.guia.cid10 )
                        .then(res => {
                            this.cid10type = "is-success"
                            this.cid10descricao = res.data[0].descricao
                        })
                        .catch(() => {
                            this.cid10type = "is-danger"
                            this.cid10descricao = null
                        })
                        .finally(() => {
                            this.cid10loading = false
                    })
                }
            },

            imprimeComprovanteGuia(guia, modelo){
                window.open('/Guia/Comprovante?id=' + guia + '&modelo=' + modelo);
            },         
            abrirAlterarDataUltimaRefeicao() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalCalculoTempoJejum,
                    events: {
                        setarHorasJejum: this.setarHorasJejum
                    },                   
                    hasModalCard: true,
                    trapFocus: true
                })
            } ,
            setarHorasJejum(horas){
                this.guia.horasDeJejum = horas;                
            },      
        }
    }
</script>