<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <div class="columns is-multiline">
                <div class="column is-6">
                    <b-field :label="$t('RELACAOAMOSTRAS.CODIGO')">
                        <b-input type="text" v-model="codigoBarra"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="unidade.id" :item.sync="unidade" table="Unidade" :label="$t('PROCURAEXAME.UNIDADE')" />
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="local.id" :item.sync="local" table="Local" :label="$t('RELACAOAMOSTRAS.LOCAL')" />
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="convenio.id" :item.sync="convenio" table="Convenio" :label="$t('RELACAOAMOSTRAS.PLANO')" />
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="paciente.id" :item.sync="paciente" table="Paciente" :label="$t('SISTEMA.PACIENTE')" :campos="[{nome:'Nasc', campo: 'dataDeNascimentoFormatada'}, {nome:'  CPF', campo: 'cpf'}]" />
                </div>
                <div class="column is-6">
                    <!--<searchIdName :id.sync="setor.id" :item.sync="setor" table="Setor" :label="$t('PROCURAEXAME.SETOR')" :setoramostra="true" />-->
                    <b-field :label="$t('ESTUDOS.SETOR')">
                        <b-taginput v-model="setoresSelecionados"
                                    :data="setoresLista"
                                    autocomplete
                                    :allow-new="false"
                                    :open-on-focus="openOfFocus"
                                    field="nome"
                                    icon="label"
                                    :placeholder="$t('ESTUDOS.SELECIONARSETORES')"
                                    @typing="buscarSetor">
                            <template slot-scope="props">
                                <div class="media">
                                    <div class="media-left">
                                        <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                    </div>
                                    <div class="media-content">
                                        {{ props.option.nome }}
                                    </div>
                                </div>
                            </template>
                        </b-taginput>
                    </b-field>
                </div>

                <div class="column is-6">
                    <searchIdName :id.sync="exame.id" :item.sync="exame" table="Exame" :label="$t('PROCURAEXAME.EXAME')" searchField="texto" :campos="[{nome:'Apelido', campo: 'apelido'},{nome: 'Setor', campo: 'setorNome'}]" />
                </div>
                <div class="column is-6">
                    <b-field :label="$t('RELACAOAMOSTRAS.AMOSTRASTATUS')">
                        <b-taginput v-model="statusSelecionados"
                                    :data="statusLista"
                                    autocomplete
                                    :allow-new="false"
                                    :before-adding="(item) => { this.statusLista = this.status; return item;}"
                                    :open-on-focus="openOfFocus"
                                    field="status"
                                    icon="label"
                                    :placeholder="$t('RELACAOAMOSTRAS.SELECIONESTATUS')"
                                    @typing="buscarStatus"
                                    ref="tagInput">
                        </b-taginput>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Urgentes">
                        <b-select v-model="urgencia">
                            <option :value="null">Todos</option>
                            <option value="urgentes">Urgentes</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field class="is-pulled-right" :label="$t('PROCURAEXAME.RESULTADOPAGINA')">
                        <b-select class="is-pulled-right" v-model="perPage" :disabled="!isPaginated">
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-6">
                    <div class="panel">
                        <div class="panel-block">
                            <periodoHora :dataInicial.sync="datas[0]"
                                         :dataFinal.sync="datas[1]" />
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="has-text-centered">
                <b-button type="is-success" :loading="loading" @click="carregarAmostras()" class="custom-button">
                    <b-icon icon="magnify"></b-icon>
                </b-button>
            </div>
        </div>

        <div class="box" v-if="!searchInitiated && amostras.length === 0">
            <div class="has-text-centered">
                <p>{{ $t('PROCURAEXAME.BOTAOPARABUSCA') }}</p>
            </div>
        </div>
        
        <div class="box" v-if="amostras.length > 0">
            <div class="table-container">
                <div class="has-text-centered is-pulled-right" v-if="amostras.length !== 0">
                    <p class="has-text-weight-bold">{{ $t('PROCURAEXAME.TOTALAMOSTRAS') }}: {{ total }}</p>
                </div>
                <b-table :data="amostras"
                         :loading="loading"
                         striped
                         :total="total"
                         :paginated="isPaginated"
                         backend-pagination
                         @page-change="onPageChange"
                         :aria-next-label="$t('PROCURAEXAME.PROXPAGINA')"
                         :aria-previous-label="$t('PROCURAEXAME.PAGINAANTERIOR')"
                         :aria-page-label="$t('PROCURAEXAME.PAGINA')"
                         :aria-current-label="$t('PROCURAEXAME.PAGINAATUAL')"
                         :per-page="perPage">

                    <template>
                        <b-table-column v-slot="props" field="Código de barra" :label="$t('RELACAOAMOSTRAS.CODIGO')">
                            <router-link :to="{ name: 'guia', params: { id: props.row.guiaId }}">
                                {{ props.row.amostraCodigo }}
                            </router-link>
                        </b-table-column>
                        <b-table-column v-slot="props" field="Lote" :label="$t('RELACAOAMOSTRAS.LOTE')">
                            <template v-if="props.row.lote">
                                {{ props.row.lote }}
                            </template>
                            <template v-else>
                                <span class="has-text-centered" style="display: block;">--</span>
                            </template>
                        </b-table-column>

                        <b-table-column v-slot="props" field="Nome do Paciente" :label="$t('RELACAOAMOSTRAS.PACIENTENOME')">
                            <a :href="'/atendimento/paciente/' + props.row.pacienteId">{{ props.row.paciente }}</a>
                            <div class="inline-container">
                                <p class="small-text" v-if="props.row.pacienteDataNascimento">Nasc: {{ $moment(props.row.pacienteDataNascimento).format("DD/MM/YYYY") }}</p>
                                <p class="small-text" v-if="props.row.pacienteCpf">CPF: {{ props.row.pacienteCpf }}</p>
                            </div>
                        </b-table-column>

                        <b-table-column v-slot="props" field="Convênio" :label="$t('RELACAOAMOSTRAS.CONVENIO')">
                            {{ props.row.convenio }}
                        </b-table-column>

                        <b-table-column v-slot="props" field="Local de origem" :label="$t('RELACAOAMOSTRAS.LOCAL')">
                            {{ props.row.local }}
                        </b-table-column>

                        <b-table-column v-slot="props" field="Unidade" :label="$t('RELACAOAMOSTRAS.UNIDADE')">
                            {{ props.row.unidade }}
                        </b-table-column>

                        <b-table-column v-slot="props" field="Conservante" :label="$t('RELACAOAMOSTRAS.CONSERVANTE')">
                            {{ props.row.conservante }}
                        </b-table-column>

                        <b-table-column v-slot="props" field="Status" :label="$t('RELACAOAMOSTRAS.STATUS')">
                            <b-tag class="prds-tag has-text-centered is-size-6" :style="props.row.statusHtmlStyle">
                                <span class="sigla">{{ props.row.statusTitle }}</span>
                            </b-tag>
                        </b-table-column>

                        <b-table-column v-slot="props" field="Exames" :label="$t('RELACAOAMOSTRAS.EXAMES')">
                            {{ props.row.examesApelido.join(", ") }}
                        </b-table-column>
                        <b-table-column v-slot="props">
                            <b-tooltip v-if="props.row.examesApelidoENome && props.row.examesApelidoENome.length > 0" :label="props.row.examesApelidoENome.join(', ')" position="is-left">
                                <b-icon icon="information" class="has-text-info"></b-icon>
                            </b-tooltip>
                            <b-icon v-else icon="information" class="has-text-grey-light" disabled></b-icon>
                        </b-table-column>
                    </template>
                </b-table>
            </div>
        </div>
    </section>
</template>

<style scoped>

    .inline-container {
        display: flex;
        align-items: flex-start;
        white-space: nowrap;
    }

    .inline-container p {
            margin-right: 10px;
    }

    .prds-tag {
        border-radius: 16px;
    }

    .custom-button {
        width: 100px;
    }
    .table-container {
        overflow-x: auto;
    }

    .box {
        white-space: nowrap;
    }

    .small-text {
        font-size: 0.75rem;
    }


</style>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import periodoHora from '@/components/periodoHora.vue'

    export default {
        components: {
            titlebar,
            searchIdName,
            periodoHora
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.EXAME'),
                    this.$t('RELACAOAMOSTRAS.PROCURADEAMOSTRAS'),
                ]
            },
            ...mapState([
                'statusAmostra',
            ])
        },
        async created() {
            await this.loadSetores();
            if (this.$route.query) {
                    if (Array.isArray(this.$route.query.setorid)) {
                      
                        this.setoresAmostras = this.$route.query.setorid.map(id => parseInt(id));                               
                    } else if (this.$route.query.setorid) {
                       
                        this.setoresAmostras = [parseInt(this.$route.query.setorid)];                    
                    }

        
                if (this.$route.query.unidadeId) {
                    this.unidade.id = parseInt(this.$route.query.unidadeId)
                }

                if (this.$route.query.localId) {
                    this.local.id = parseInt(this.$route.query.localId)
                }

                if (this.$route.query.convenioId) {
                    this.convenio.id = parseInt(this.$route.query.convenioId)
                }
                if (Array.isArray(this.$route.query.status)) {
                    const statuses = this.$route.query.status.flat();
                    this.statusSelecionados = Array.from(statuses).map(status => {
                        switch (parseInt(status)) {
                            case 2:
                                return 'Solicitada';
                            case 536870912:
                                return 'TriadaParaTransporte';
                            case 1024:
                                return 'EnviadaParaEquipamento';
                            case 32:
                                return 'Triagem';
                            case 8:
                                return 'EmTransito';
                            case 16:
                                return 'Recebida';
                            case 256:
                                return 'ArmazenadaSoroteca';
                            case 2048:
                                return 'SolicitadaNovaAmostra';
                            case 4096:
                                return 'Incidência';
                            case 64:
                                return 'RecebidaPeloSetor';
                            case 268435456:
                                return 'EnviadoParaApoio';
                            case 128:
                                return 'DevolvidaPeloSetor';
                            case 33554432:
                                return 'AlterouStatus';
                            case 67108864:
                                return 'ResultadoRecebidoDeEquipamento';
                            case 1048576:
                                return 'AceitaComRestricao';
                            default:
                                return '';
                        }
                    }).filter(status => status !== '');
                } else {
                    const statusId = parseInt(this.$route.query.status);
                    switch (statusId) {
                        case 2:
                            this.statusSelecionados = ['Solicitada'];
                            break;
                        case 536870912:
                            this.statusSelecionados = ['TriadaParaTransporte'];
                            break;
                        case 1024:
                            this.statusSelecionados = ['EnviadaParaEquipamento'];
                            break;
                        case 32:
                            this.statusSelecionados = ['Triagem'];
                            break;
                        case 8:
                            this.statusSelecionados = ['EmTransito'];
                            break;
                        case 16:
                            this.statusSelecionados = ['Recebida'];
                            break;
                        case 256:
                            this.statusSelecionados = ['ArmazenadaSoroteca'];
                            break;
                        case 2048:
                            this.statusSelecionados = ['SolicitadaNovaAmostra'];
                            break;
                        case 4096:
                            this.statusSelecionados = ['Incidência'];
                            break;
                        case 64:
                            this.statusSelecionados = ['RecebidaPeloSetor'];
                            break;
                        case 268435456:
                            this.statusSelecionados = ['EnviadoParaApoio'];
                            break;
                        case 128:
                            this.statusSelecionados = ['DevolvidaPeloSetor'];
                            break;
                        case 33554432:
                            this.statusSelecionados = ['AlterouStatus'];
                            break;
                        case 67108864:
                            this.statusSelecionados = ['ResultadoRecebidoDeEquipamento'];
                            break;
                        case 1048576:
                            this.statusSelecionados = ['AceitaComRestricao'];
                            break;
                        default:
                            this.statusSelecionados = [];
                            break;
                    }
                }
                if (this.$route.query.dias) {
                    this.datas = [
                        this.$moment().subtract(this.$route.query.dias, 'days').toDate(),
                        this.$moment().toDate()
                    ]
                }

            }

            setTimeout(() => {
                this.carregarAmostras();
            }, 500);
        },
        watch: {
            status: function (newValue) {
                if (newValue) {
                    this.setoresLista = newValue;
                }
            },
            

        },
        data() {
            return {
                codigoBarra: null,
                openOfFocus: true,
                 urgencia: null,
                local: {
                    id: null
                },
                unidade: {
                    id: null
                },
                convenio: {
                    id: null
                },
                paciente: {
                    id: null
                },
                responsavel: {
                    id: null
                },
                setor: {
                    id: null
                },               
                exame: {
                    id: null
                },
                datas: [
                    this.$moment().subtract(1, 'days').toDate(),
                    this.$moment().toDate()
                ],
                dataFiltro: 'solicitacao',
                statusSelecionados: [],              
                setoresAmostras: [],
                setores: [],
                statusLista: this.setores,
                amostras: [],
                loading: false,
                checkedRows: [],
                checkboxPosition: 'left',
                page: 1,
                total: 0,
                perPage: 10,
                isPaginated: true,
                searchInitiated: false,
                setoresLista: this.setorId,
                setoresSelecionados: []
            }
        },

        methods: {         
             buscarSetor(text) {
                 this.setoresLista = this.setores;
                 this.setoresLista = this.setoresLista.filter((option) => {
                     return option.nome
                         .toString()
                         .toLowerCase()
                         .indexOf(text.toLowerCase()) >= 0
                 })
             },
            loadSetores(){
                this.$http.get('/api/search/setores')
                    .then(res => {
                        this.setores = res.data;
                        this.filtrarSetoresSelecionados();
                    });
   
            },
            filtrarSetoresSelecionados() {
        this.setoresSelecionados = this.setores.filter(setor => this.setoresAmostras.includes(setor.id));
    },
            buscarStatus(text) {
                this.statusLista = this.statusAmostra;
                this.statusLista = this.statusLista.filter((option) => {
                    return option
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },
            carregarAmostras() {

                
                this.searchInitiated = true
                const params = [
                    `pageNumber=${this.page}`,                   
                    `statusAmostras=${this.statusSelecionados.toString()}`,
                    `dataInicial=${this.$moment(this.datas[0]).format('YYYY-MM-DDTHH:mm:ss')}`,
                    `dataFinal=${this.$moment(this.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`,
                    `perPage=${this.perPage}`
                ]
                if (this.unidade?.id != null) {
                    params.push(`unidadeId=${this.unidade.id}`)
                }

                if (this.codigoBarra != null) {
                    params.push(`codigoBarra=${this.codigoBarra}`);
                }

                 if (this.urgencia != null) {                    
                       params.push(`urgencia=${this.urgencia}`);
                 }

                if (this.local != null && this.local.id != null) {
                    params.push(`localId=${this.local.id}`);
                }
                if (this.setoresSelecionados.length > 0) {
    this.setoresSelecionados.forEach(item => {
        params.push(`setorid=${item.id}`);
    });
}


                if (this.exame != null && this.exame.id != null) {
                    params.push(`exameId=${this.exame.id}`);
                }

                if (this.convenio != null && this.convenio.id != null) {
                    params.push(`convenioId=${this.convenio.id}`);
                }

                if (this.paciente != null && this.paciente.id != null) {
                    params.push(`pacienteId=${this.paciente.id}`);
                }

                if (this.responsavel != null && this.responsavel.id != null) {
                    params.push(`responsavelId=${this.responsavel.id}`);
                }

                if (this.examesAtrasados) {
                    params.push('examesAtrasados=true')
                }

                this.loading = true;
                this.$http.get(`/api/analitico/procuradeamostras?${params.join('&')}`)
                    .then(({ data }) => {
                        this.amostras = data.lista
                        this.total = data.totalData
                        this.loading = false
                    })
                    .catch((error) => {
                        this.total = 0
                        this.loading = false
                        throw error
                    })

                

            },
            onPageChange(page) {
                this.page = page
                this.carregarAmostras()
            }
        }
    }
</script>